import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

import styled, { css } from "styled-components"
import { device } from "../../globalStyles"
import useBreakpoint from "../../hooks/useBreakpoint"

export const ImageWrapper = styled.div<{ rightAlign?: boolean }>`
  position: absolute;
  width: 60vw;
  max-width: 800px;
  opacity: 1;

  top: ${props => (props.rightAlign ? `40vh` : `0`)};
  ${props =>
    props.rightAlign
      ? css`
          right: -20px;
        `
      : css`
          left: -20px;
        `};

  @media ${device.tabletLandscape} {
    width: inherit;
    grid-column: ${props => (props.rightAlign ? `7 / span 5` : `2 / span 5`)};
  }

  > img {
    width: 100%;
    height: auto;
    position: relative;
  }

  @media ${device.desktop} {
    font-size: 22px;
    width: 100%;
  }
`

interface ImageProps {
  imgSrc: string
  rightAlign?: boolean
  className?: string
}

export default ({ imgSrc, rightAlign = false, className }: ImageProps) => {
  if (typeof window === "undefined") return null

  const sectionImage = useRef<HTMLDivElement>()

  const { isMobile } = useBreakpoint()

  useEffect(() => {
    gsap.to(sectionImage.current, {
      scrollTrigger: {
        trigger: sectionImage.current,
        scrub: true,
      },
      transform: isMobile
        ? "translate3d(0, -25vh, 0)"
        : "translate3d(0, -10vh, 0)",
      opacity: isMobile ? "0.2" : "1",
      ease: "Linear.easeNone",
      start: "top center",
    })
  }, [isMobile])

  return (
    <ImageWrapper
      className={className}
      rightAlign={rightAlign}
      ref={sectionImage}
    >
      <img src={imgSrc}></img>
    </ImageWrapper>
  )
}
