import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { device } from "../../globalStyles"
import ScrollReveal from "../scrollReveal"

const ContactHeadline = styled.h2`
  align-self: flex-end;
  grid-column: 2 / span 10;
`

const ContactContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 60px;
  width: 100%;

  @media ${device.tablet} {
    grid-column: 2 / span 10;
    margin-top: 80px;
  }
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;

  line-height: 1.1;

  a,
  a:active,
  a:visited {
    color: ${props => props.theme.text};
  }

  b {
    margin-top: 10px;

    @media ${device.tablet} {
      margin-top: 20px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  p {
    margin-top: 30px;
    max-width: 500px;
  }
`

const ContactImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  margin-top: calc(var(--BASE_GUTTER) * 2);

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  align-self: flex-start;

  @media ${device.tablet} {
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 0;
    width: 60%;
  }
`

const SocialTagline = styled.div`
  margin-top: -5px;
  grid-column: 1 /-1;

  @media ${device.tablet} {
    margin-top: -15px;
  }

  > a {
    color: inherit;
    line-height: 1;
    display: inline-block;
    padding-bottom: 1px;
    border-bottom: 1px solid;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
`

const BottomSvg = styled.svg`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(61%);
  fill: ${props => props.theme.background};
`

export default () => {
  // TODO: Check why these images are linked and not sourced...
  // const { allInstaNode } = useStaticQuery(graphql`
  //   query {
  //     allInstaNode(limit: 6) {
  //       nodes {
  //         original
  //       }
  //     }
  //   }
  // `)

  // const { nodes: images } = allInstaNode

  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "contact" } }
        sort: { fields: name }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720, quality: 100) {
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  `)

  const { nodes: images } = allFile

  return (
    <>
      <ContactHeadline>Kontakt</ContactHeadline>

      <ContactContent>
        <ContactInfo>
          <b>adress</b>
          <div>folkungagatan 96</div>
          <div>116 30 stockholm</div>

          <b>telefon</b>
          <a href="tel:08 121 476 72">08 121 476 72</a>

          <b>e-post</b>
          <a href="mailto:info@nytand.se">info@nytand.se</a>
          <p>
            Uteblivande/sent återbud debiteras med timtaxa 1200kr/h. <br />
            Dessvärre kan vi inte ta hänsyn till sena återbud pga vab/jobb/möten utan de debiteras enligt ovan taxa.
          </p>
        </ContactInfo>
        <ContactImages>
          {images.map((image, index) => {
            return (
              <ScrollReveal key={`insta-${index}`}>
                <ImageWrapper>
                  <img src={image.childImageSharp.fluid.srcWebp} />
                </ImageWrapper>
              </ScrollReveal>
            )
          })}
          <SocialTagline>
            Se mer av oss på{" "}
            <a href="https://www.facebook.com/nytand/" target="_blank">
              Facebook
            </a>{" "}
            och{" "}
            <a
              href="https://www.instagram.com/explore/locations/595031960674257/"
              target="_blank"
            >
              Instagram
            </a>
          </SocialTagline>
        </ContactImages>
      </ContactContent>

      <BottomSvg viewBox="8 65 737 137" fill="none">
        <path d="M8.27266 65.4998C8.27266 65.4998 8.27267 65.5001 8.27267 123.717C87.5569 178.086 191.086 162.071 278.018 136.718C316.338 125.46 357.179 111.877 395.956 122.763C452.595 138.424 485.975 189.881 547.275 198.448C611.963 207.528 688.919 204.558 744.308 169.21C744.308 169.21 747.157 172.402 744.308 65.5L8.27266 65.4998Z" />
      </BottomSvg>
    </>
  )
}
