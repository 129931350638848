import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"

import useIntersectingRef from "../hooks/useIntersectingRefs"

const RevealContainer = styled.div<{ fadeIn?: boolean }>`
  transition: opacity 800ms cubic-bezier(0.47, 0, 0, 1),
    transform 800ms cubic-bezier(0.47, 0, 0, 1);

  ${props =>
    props.fadeIn
      ? `      
      opacity: 1;
      `
      : `
      transform: translate3d(0, 20px, 0);
      opacity: 0.01;
    `};
`

interface Props {
  children: React.ReactNode
  className?: string
}

export default (props: Props) => {
  const [visible, setVisible] = useState(false)
  const fadeRef = useRef<HTMLDivElement>()

  const options = { rootMargin: "-2.5%" }

  const intersecting = useIntersectingRef({
    elementRef: fadeRef,
    options,
  })

  useEffect(() => {
    if (intersecting && !visible) {
      setVisible(true)
    }
  }, [intersecting])

  return (
    <RevealContainer className={props.className} fadeIn={visible} ref={fadeRef}>
      {props.children}
    </RevealContainer>
  )
}
