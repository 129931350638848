import React, { useState } from "react"
import styled, { keyframes, css } from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Logo from "../assets/svg/logo.svg"
import { gridStyles, device, colors } from "../globalStyles"
import useBreakpoint from "../hooks/useBreakpoint"
import { useStaticQuery, graphql } from "gatsby"

const Header = styled.header`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: fixed;

  ${gridStyles}
  flex-wrap: nowrap;
  z-index: 40;

  top: var(--BASE_GUTTER);
  left: var(--BASE_GUTTER);
  right: var(--BASE_GUTTER);

  @media ${device.tabletLandscape} {
    top: 20px;
    margin: 0 calc(var(--BASE_GUTTER) * -1);
  }

  > a {
    grid-column: 2 / span 3;
  }

  svg {
    fill: ${props => props.theme.text};
    display: block;

    g {
      stroke: ${props => props.theme.text};
      fill: ${props => props.theme.text};
    }
  }
`

const StyledLogo = styled(Logo)`
  height: 30px;
  width: auto;
`

const TopNav = styled.nav`
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  grid-column: 8 / span 4;
  justify-self: end;
  line-height: 1;
`

const Burger = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  right: 20px;
  height: 30px;
  width: 24px;

  transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-tap-highlight-color: ${colors.lightGreen};

  transform: ${props => (props.isOpen ? `rotate(360deg)` : `rotate(0)`)};

  display: flex;
  justify-content: center;
  flex-direction: column;

  z-index: 100;

  > div {
    background-color: ${props =>
      props.isOpen ? colors.white : props.theme.text};
  }
`

const BurgerLine = styled.div`
  height: 3px;
  margin-bottom: 3px;
  border-radius: 10px;
  width: 100%;
`

const appearTransition = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-20px); 
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
`

const opacityTransition = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`

const transitionDuration = 400

const MobileNav = styled.nav<{ isOpen?: boolean }>`
  background-color: ${colors.darkGreen};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: lowercase;

  transform: ${props => (props.isOpen ? `translateY(0)` : `translateY(-100%)`)};
  transition: transform ${transitionDuration}ms
    cubic-bezier(0.645, 0.045, 0.355, 1);

  > div {
    font-family: "Poppins";
    font-size: 60px;
    font-weight: 800;
    color: ${colors.white};
    text-align: center;

    animation: ${props =>
      props.isOpen
        ? css`
            ${appearTransition} ${transitionDuration}ms
          `
        : "none"};
    opacity: 0;
    animation-fill-mode: forwards;
    animation-delay: ${transitionDuration - 100}ms;
  }

  > a {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-decoration: underline;
    font-size: 24px;
    font-weight: 600;

    animation: ${props =>
      props.isOpen
        ? css`
            ${opacityTransition} ${transitionDuration}ms
          `
        : "none"};
    opacity: 0;
    animation-fill-mode: forwards;
    animation-delay: ${transitionDuration - 100}ms;
  }
`

const Link = styled(AnchorLink)`
  margin-left: 20px;
  color: inherit;

  &:hover {
    opacity: 0.8;
  }
`

const links = [
  {
    sectionId: "about",
    title: "Om oss",
  },
  {
    sectionId: "dental",
    title: "Tandvård",
  },
  {
    sectionId: "cosmetic",
    title: "Estetik",
  },
  {
    sectionId: "media",
    title: "Media",
  },
  {
    sectionId: "contact",
    title: "Kontakt",
  },
]

export default () => {
  if (typeof window === "undefined" || !window.document) return null
  const { sanitySiteSettings } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        bookingLink
      }
    }
  `)

  const { isMobile } = useBreakpoint()
  const [menuOpen, setMenuOpen] = useState(false)

  const { bookingLink } = sanitySiteSettings
  const toggleMenu = () => setMenuOpen(!menuOpen)

  return (
    <Header>
      <AnchorLink to="/">
        <StyledLogo />
      </AnchorLink>
      {isMobile ? (
        <>
          <Burger isOpen={menuOpen} onClick={() => toggleMenu()}>
            <BurgerLine />
            <BurgerLine />
            <BurgerLine />
          </Burger>

          <MobileNav isOpen={menuOpen}>
            {links.map((linkItem, index) => {
              const { sectionId, title } = linkItem
              const section = document.getElementById(sectionId)

              return (
                <div
                  key={`mobile-nav-${index}`}
                  onClick={() => {
                    toggleMenu()

                    section.scrollIntoView({ behavior: "smooth" })
                  }}
                >
                  <span>{title}</span>
                </div>
              )
            })}

            <a href={bookingLink} target="_blank">
              Boka tid
            </a>
          </MobileNav>
        </>
      ) : (
        <TopNav>
          {links.map((linkItem, index) => {
            const { sectionId, title } = linkItem
            return (
              <Link to={`/#${sectionId}`} key={`nav-${index}`} stripHash>
                <span>{title}</span>
              </Link>
            )
          })}
        </TopNav>
      )}
    </Header>
  )
}
