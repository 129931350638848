import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import { SectionBody, device, bodyTextStyles } from "../../globalStyles"
import PriceList from "./PriceList"
import Slideshow from "../Slideshow"
import ScrollReveal from "../scrollReveal"

import PriceBlob from "../../assets/svg/price-blob.svg"

const PriceContainer = styled.div`
  position: relative;
  margin: 20vh auto 0;
  max-width: 1150px;
  width: 100%;

  @media (max-width: 340px) {
    margin-left: -20px;
  }

  @media ${device.tabletLandscape} {
    display: flex;
    justify-content: center;
    grid-column: 1 / -1;
  }

  @media ${device.tabletLandscape} {
    display: flex;
    justify-content: center;
    grid-column: 1 / -1;
  }
`

const PriceWrapper = styled.div`
  color: ${props => props.theme.primaryText};
  position: relative;
  font-size: 18px;

  display: flex;
  justify-content: center;
  justify-content: flex-start;
  max-width: 430px;
  min-width: 320px;

  flex-direction: column;
  align-items: center;

  width: 80%;
  margin: 0 auto 40px;

  ${bodyTextStyles};

  @media ${device.tabletLandscape} {
    width: 33%;
    height: 26vw;
    position: sticky;
    top: 25vh;

    &:first-of-type {
      margin-bottom: 40px;
    }

    &:nth-of-type(2) {
      top: 18vh;
      margin-left: -8vw;
      margin-top: 60vh;
      margin-bottom: 120px;

      > svg {
        transform: rotate(100deg);
      }
    }

    &:last-of-type {
      margin-top: 120vh;
      margin-left: -10vw;

      > svg {
        transform: rotate(15deg);
      }
    }
  }

  @media ${device.desktopLarge} {
    &:last-of-type {
      margin-left: -150px;
    }
  }

  > h3 {
    margin-top: 50px;
  }
`

const BackgroundBlob = styled(PriceBlob)`
  fill: ${props => props.theme.text};
  position: absolute;
  top: 0;
  height: auto;
  width: 100%;

  path {
    fill: ${props => props.theme.text};
    opacity: 0.16;
  }
`
const StyledPriceList = styled(PriceList)`
  width: 75%;

  > li {
    display: flex;
    justify-content: space-between;
  }
`

const StyledSlideshow = styled(Slideshow)`
  margin-top: 30vh;
  width: 100vw;

  @media ${device.tabletLandscape} {
    width: 100%;
  }
`

const SlideshowWrapper = styled(ScrollReveal)`
  width: 100%;
  margin-left: calc(var(--BASE_GUTTER) * -1);
  grid-column: 1 / -1;

  @media ${device.tabletLandscape} {
    margin-left: 0;
  }
`

const treatments = [
  {
    title: "botox",
    prices: [
      {
        name: "1 område",
        price: "2950kr",
      },
      {
        name: "2 områden",
        price: "3900kr",
      },
      {
        name: "3 områden",
        price: "4500kr",
      },
      {
        name: "Käkmuskler",
        price: "5000kr",
      },
      {
        name: "Mindre områden",
        price: "1500kr",
      },
    ],
  },
  {
    title: "fillers",
    prices: [
      {
        name: "0.55 - 0.6ml",
        price: "3300kr",
      },
      {
        name: "1ml",
        price: "4100kr",
      },
      {
        name: "Ytterligare ml",
        price: "3500kr",
      },
    ],
  },
  {
    title: "profilho",
    prices: [
      {
        name: "1 behandling",
        price: "3950kr",
      },
      {
        name: "2 behandlingar",
        price: "6700kr",
      },
    ],
  },
]

export default () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "dental" } }
        sort: { fields: name }
      ) {
        nodes {
          childImageSharp {
            fluid(quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const { nodes: images } = allFile

  return (
    <>
      <SectionBody>
        <h2>estetik</h2>
        <p>
          När det kommer till estetiska behandlingar är det viktigt för oss att
          du känner dig trygg. Vi är del av det estetiska injektionsrådet och
          det är enbart legitimerade tandläkare som utför behandlingar. Men
          viktigast av allt är vårt fokus ligger på välmående, vi utför
          estetiska behandlingar med realistiska resultat efter att vi har
          konsulterat med dig om ditt mål.
        </p>
      </SectionBody>
      <PriceContainer>
        {treatments.map((treatment, index) => {
          return (
            <PriceWrapper key={`treatment-${index}`}>
              <BackgroundBlob />
              <h3>{treatment.title}</h3>

              <StyledPriceList list={treatment.prices} />
            </PriceWrapper>
          )
        })}
      </PriceContainer>

      <SlideshowWrapper>
        <StyledSlideshow images={images} />
      </SlideshowWrapper>
    </>
  )
}
