import React, { useEffect, useRef, useContext, ReactNode } from "react"
import styled from "styled-components"

import { GlobalStateContext } from "../context/GlobalContext"
import { device, gridStyles } from "../globalStyles"

import useIntersectingRef from "../hooks/useIntersectingRefs"

export const SectionWrapper = styled.section<{
  hideOverflow?: boolean
}>`
  padding: 20vh var(--BASE_GUTTER) 0;
  box-sizing: border-box;
  ${gridStyles};

  overflow: ${props => (props.hideOverflow ? "hidden" : "initial")};

  @media ${device.tabletLandscape} {
    padding: 20vh 0;
  }

  @media ${device.desktop} {
    overflow: initial;
  }

  &:first-of-type {
    max-width: initial;
    padding: 0;
    overflow: hidden;
    min-height: initial;

    @media ${device.tabletLandscape} {
      min-height: 100vh;
      min-height: initial;
    }
  }

  &:last-of-type {
    min-height: 120vh;
  }

  position: relative;
  width: 100%;

  h1,
  h2 {
    font-weight: 900;
    line-height: 1;

    margin-block-end: 0;
  }

  h1 {
    font-size: 8vw;

    @media ${device.tabletLandscape} {
      font-size: 5vw;
    }

    @media ${device.desktopLarge} {
      font-size: 90px;
    }
  }

  h2 {
    font-size: 60px;
    text-transform: lowercase;

    @media ${device.tablet} {
      font-size: 100px;
    }

    @media ${device.desktop} {
      font-size: 120px;
    }
  }
`

interface SectionProps {
  component: ReactNode
  sectionName: string
}

export default ({ component, sectionName }: SectionProps) => {
  const { setTheme } = useContext(GlobalStateContext)
  const sectionRef = useRef<HTMLDivElement>()
  const options = { rootMargin: "-50%" }

  const intersecting = useIntersectingRef({
    elementRef: sectionRef,
    options,
  })

  useEffect(() => {
    if (intersecting) {
      setTheme(sectionName)
    }
  }, [intersecting])

  const hideOverflow = sectionName === "dental" || sectionName === "about"

  return (
    <SectionWrapper
      ref={sectionRef}
      hideOverflow={hideOverflow}
      id={`${sectionName}`}
    >
      {component}
    </SectionWrapper>
  )
}
