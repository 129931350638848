import React from "react"

import styled from "styled-components"

import Employees from "../employees"
import team from "../../images/nytand-bg.jpg"
import { SectionBody, leftColumn, device } from "../../globalStyles"
import SectionImage, { ImageWrapper } from "./SectionImage"

const StyledBody = styled(SectionBody)`
  margin-top: 50px;
  ${leftColumn};
`

const StyledEmployees = styled(Employees)`
  margin-top: 50vh;

  @media ${device.desktop} {
    margin-top: 70vh;
  }
`

const StyledSectionImage = styled(SectionImage)`
  margin-top: 15vh;
`

export default () => {
  return (
    <>
      <StyledBody>
        <h2>om oss</h2>
        <p>
          Starkt team med kärlek för varandra, er och allt som har med tänder
          att göra. <br />
          Det är vi som ser till att ditt besök blir smidigt och trevligt! Tveka
          inte på att be oss slå på din favoritmusik eller berätta något kul om
          oss själva som distraktion, vi kan det där med multitasking!
        </p>
      </StyledBody>

      <ImageWrapper rightAlign={true}>
        <StyledSectionImage imgSrc={team} />
      </ImageWrapper>

      <StyledEmployees id="about" />
    </>
  )
}
