import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Section from "../components/Section"
import BookButton from "../components/BookButton"

import Cosmetic from "../components/sections/Cosmetic"
import Media from "../components/sections/Media"
import Contact from "../components/sections/Contact"
import LandingPage from "../components/sections/LandingPage"
import About from "../components/sections/About"
import Dental from "../components/sections/Dental"
import Header from "../components/Header"

export default () => {
  const sections = [
    {
      name: "start",
      Component: <LandingPage />,
    },
    {
      name: "about",
      Component: <About />,
    },
    {
      name: "dental",
      Component: <Dental />,
    },
    {
      name: "cosmetic",
      Component: <Cosmetic />,
    },
    {
      name: "media",
      Component: <Media />,
    },
    {
      name: "contact",
      Component: <Contact />,
    },
  ]

  return (
    <Layout>
      <SEO title="Nytorgstandläkarna Södermalm" />
      <Header />
      {sections.map((section, index) => {
        const { Component, name } = section

        return (
          <Section
            key={`section-${index}`}
            component={Component}
            sectionName={name}
          />
        )
      })}
      <BookButton />
    </Layout>
  )
}
