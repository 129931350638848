import React from "react"

interface Treatment {
  name: string
  price: string
}

export default ({
  list,
  className,
}: {
  list: Treatment[]
  className?: string
}) => {
  return (
    <ul className={className}>
      {list.map((treatment, index) => {
        return (
          <li key={`treatment-${index}`}>
            <span>{treatment.name} </span>
            <b>{treatment.price} </b>
          </li>
        )
      })}
    </ul>
  )
}
