import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "react"
import styled from "styled-components"
import debounce from "lodash.debounce"

import { GlobalStateContext } from "../context/GlobalContext"

import BookCircle from "../assets/svg/book-circle.svg"
import Tooth from "../assets/svg/tooth.svg"
import Bulbs from "../assets/svg/bulbs.svg"
import MouthClosed from "../assets/svg/mouth-closed.svg"
import MouthOpen from "../assets/svg/mouth-open.svg"
import MouthBling from "../assets/svg/mouth-bling.svg"
import { colors, gridStyles, device } from "../globalStyles"
import { useStaticQuery, graphql } from "gatsby"

const CircleText = styled(BookCircle)`
  transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
`

const StyledTooth = styled(Tooth)`
  max-width: 40px;
`
const StyledMouthOpen = styled(MouthOpen)`
  width: 50px;
`
const StyledMouthBling = styled(MouthBling)`
  width: 55px;
`
const StyledMouthClosed = styled(MouthClosed)`
  width: 60px;
`

const StyledBulbs = styled(Bulbs)`
  stroke-width: 8px;
`

const IconWrapper = styled.div`
  height: 100%;
  position: absolute;
  top: 0;

  width: 100px;

  @media ${device.tabletLandscape} {
    width: 110px;
  }

  > svg {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    height: auto;
    width: 50%;
  }

  svg,
  path {
    fill: none;
  }

  ${StyledMouthOpen},
  ${StyledMouthClosed},
  ${StyledMouthBling} {
    stroke-width: 6px;
  }
`

const Wrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 30;

  grid-column: 1 / -1;
  width: 100%;
  ${gridStyles};

  @media ${device.tabletLandscape} {
    right: initial;
    bottom: 60px;
  }
`

const BookButton = styled.a`
  grid-column: 11 / span 1;
  margin-left: auto;
  padding: 10px;
  border: ${props => `1px solid ${props.theme.logo ? props.theme.logo : props.theme.text}`} ;

  border-radius: 50%;
  backdrop-filter: blur(10px);

  transition: stroke 200ms linear, fill 200ms linear,
    transform 1200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  fill: ${props => (props.theme.logo ? props.theme.logo : props.theme.text)};

  width: 100px;

  @media ${device.tabletLandscape} {
    width: 110px;
  }

  ${IconWrapper} {
    stroke: ${props =>
      props.theme.logo ? props.theme.logo : props.theme.text};
  }

  &.displaced {
    fill: ${colors.white};
    transition: stroke 200ms linear, fill 200ms linear;
    border: none;

    ${IconWrapper} {
      stroke: ${colors.white};
    }
  }

  > svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    ${CircleText} {
      transform: rotate(360deg);
    }
  }
`

export default () => {
  const { sanitySiteSettings } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        bookingLink
      }
    }
  `)

  const { theme } = useContext(GlobalStateContext)
  const [inPlace, setInPlace] = useState(false)

  const { bookingLink } = sanitySiteSettings

  const SvgIcon = () => {
    switch (theme) {
      case "dental":
        return <StyledMouthOpen />
      case "media":
        return <StyledMouthBling />
      case "cosmetic":
        return <StyledMouthClosed />
      case "contact":
        return <StyledBulbs />
      default:
        return <StyledTooth />
    }
  }

  const buttonRef = useRef<HTMLDivElement>()
  const onScroll = () => {
    buttonRef.current.style.transform = `translate3d(${0}px, ${0}px, 0) rotate(360deg)`
    buttonRef.current.classList.remove("displaced")
  }

  const calculateCenterTransform = () => {
    const { top, left, width } = buttonRef.current.getBoundingClientRect()

    const horizontalCenter = window.innerWidth / 2
    const verticalCenter = window.innerHeight / 2
    const centerOfButton = width / 2

    const xTransform = left + centerOfButton - horizontalCenter
    const yTransform = top + centerOfButton - verticalCenter

    return {
      xTransform,
      yTransform,
    }
  }

  const placeButtonInMiddle = () => {
    if (inPlace) return
    const { xTransform, yTransform } = calculateCenterTransform()

    buttonRef.current.style.transform = `translate3d(-${xTransform}px, -${yTransform}px ,0)`
  }

  const fixOnResize = debounce(() => {
    if (!inPlace) {
      setInPlace(true)
    }
  }, 200)

  useLayoutEffect(() => {
    if (!buttonRef.current) return

    if (window.scrollY < 10) {
      placeButtonInMiddle()
    }
  }, [inPlace])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    window.addEventListener("resize", fixOnResize)

    return () => {
      window.removeEventListener("scroll", onScroll)
      window.addEventListener("resize", fixOnResize)
    }
  }, [inPlace])

  return (
    <>
      <Wrapper>
        <BookButton
          href={bookingLink}
          ref={buttonRef}
          className={!inPlace ? "displaced" : ""}
        >
          <CircleText />
          <IconWrapper>
            <SvgIcon />
          </IconWrapper>
        </BookButton>
      </Wrapper>
    </>
  )
}
