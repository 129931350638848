import { useEffect, useCallback } from "react"
import { useState } from "react"
import debounce from "lodash.debounce"

const getScreenSize = (screenWidth?: number) => {
  const isMobile = screenWidth < 768
  const isTablet = !isMobile && screenWidth < 1024
  const isDesktop = !isMobile && !isTablet

  return {
    isMobile,
    isTablet,
    isDesktop,
  }
}

export default () => {
  const getWindowSize = useCallback(() => {
    return typeof window === "undefined" ? undefined : window.innerWidth
  }, [])

  const [screenSizes, setScreenSizes] = useState(() =>
    getScreenSize(getWindowSize())
  )

  useEffect(() => {
    const resizedScreenSize = debounce(() => {
      const screenWidth = getWindowSize()
      const sizes = getScreenSize(screenWidth)
      setScreenSizes(sizes)
    }, 200)

    window.addEventListener("resize", resizedScreenSize)

    return () => {
      window.removeEventListener("resize", resizedScreenSize)
    }
  }, [])

  return screenSizes
}
