import React, { createRef } from "react"
import Img, { FluidObject } from "gatsby-image"
import styled from "styled-components"

import { device } from "../globalStyles"

const Slideshow = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
  height: 60vh;
  grid-column: 1 / -1;
  overflow-x: scroll;

  display: flex;

  @media ${device.tabletLandscape} {
    height: 80vh;
    padding: 40px;
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  height: 100%;
  margin-left: 20px;
  min-width: 70vw;
  overflow: hidden;

  &:first-of-type {
    margin-left: 0px;
    min-width: 120vw;
  }

  &:last-of-type {
    padding-right: 20px;
  }

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media ${device.tabletLandscape} {
    margin-left: 40px;
    min-width: 50vw;

    &:first-of-type {
      min-width: 70vw;
    }

    &:last-of-type {
      padding-right: 40px;
    }
  }
`

interface Images {
  images: [
    {
      childImageSharp: {
        fluid: FluidObject | FluidObject[]
      }
    }
  ]
  className?: string
}

export default ({ images, className }: Images) => {
  const slideshow = createRef<HTMLDivElement>()

  return (
    <Slideshow className={className} ref={slideshow}>
      {images.map(image => {
        return (
          <ImageWrapper key={`slideshow-${Math.random()}`}>
            <Img fluid={image.childImageSharp.fluid} />
          </ImageWrapper>
        )
      })}
    </Slideshow>
  )
}
