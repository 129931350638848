import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { device, colors } from "../globalStyles"
import ScrollReveal from "./scrollReveal"

import placeholder from "../images/employees/placeholder.png"

const Employees = styled.div`
  grid-column: 2 / span 10;
  width: 100%;

  column-gap: 20px;
  row-gap: 20px;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-self: flex-start;

  font-size: 16px;

  @media ${device.tablet} {
    font-size: 18px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media ${device.tabletLandscape} {
    font-size: 18px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  > h3 {
    font-size: 24px;
    font-weight: 400;
    width: 100%;
    grid-column: 1/ -1;
    line-height: 0;
  }
`

const Employee = styled(ScrollReveal)``

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;

  position: relative;
  height: 0;
  padding-bottom: 133%;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &.placeholder {
    background-color: rgba(${colors.darkGreenRgb}, 0.2);

    > img {
      width: 65%;
      object-fit: contain;
      object-position: bottom;

      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const Name = styled.div`
  margin-top: 10px;
  width: 100%;
  font-weight: 600;
  line-height: 1.1;
`

const WorkTitle = styled.div`
  margin-top: -5px;
`

interface Employee {
  name: string
  workTitle: string
  image: {
    asset: {
      url: string
    }
    alt: string
  }
}

interface SanityEmployees {
  sanityEmployees: {
    employeeList: Employee[]
  }
}

export default ({ className }) => {
  const { sanityEmployees }: SanityEmployees = useStaticQuery(graphql`
    query {
      sanityEmployees {
        employeeList {
          name
          workTitle
          image {
            asset {
              url
            }
            alt
          }
        }
      }
    }
  `)

  const { employeeList } = sanityEmployees

  return (
    <Employees className={className}>
      <h3>Vi som jobbar här </h3>
      {employeeList.map((employee, index) => {
        const { name, workTitle, image } = employee

        const isPlaceholder = !image?.asset?.url

        return (
          <Employee key={`employee-${index}`}>
            <ImageWrapper
              key={`slideshow-${Math.random()}`}
              className={isPlaceholder ? "placeholder" : ""}
            >
              {image?.asset?.url ? (
                <img src={image?.asset?.url} />
              ) : (
                <img src={placeholder} />
              )}
            </ImageWrapper>
            <Name>{name}</Name>
            <WorkTitle>{workTitle}</WorkTitle>
          </Employee>
        )
      })}
    </Employees>
  )
}
