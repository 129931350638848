import React from "react"
import styled from "styled-components"

import { SectionBody, device } from "../../globalStyles"
import ScrollReveal from "../scrollReveal"

import p1 from "../../images/media-section/kropp-och-sjal.jpg"
import metroMode from "../../images/media-section/metro-mode.png"
import breakingNews from "../../images/media-section/breaking-news.jpg"
import aftonbladet from "../../images/media-section/logo-aftonbladet.jpg"
import elle from "../../images/media-section/elle-logo.jpg"
import cafe from "../../images/media-section/cafe-logo.jpg"

const MediaTeasers = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  grid-column: 2 / span 10;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: var(--BASE_GUTTER);
  row-gap: var(--BASE_GUTTER);
  align-self: flex-start;
  margin: calc(var(--BASE_GUTTER) * 2) auto 0;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.desktopLarge} {
    grid-template-columns: repeat(3, 1fr);
  }
`
const Teaser = styled.a`
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const TeaserText = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 5px 0 0;
  color: ${props => props.theme.text};
  width: 100%;
`

const Name = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: lowercase;

  @media ${device.tabletLandscape} {
    font-size: 16px;
  }
`

const Description = styled.div`
  font-size: 16px;
  font-family: "Work Sans";

  @media ${device.tabletLandscape} {
    font-size: 22px;
  }
  line-height: 1.1;
  margin-top: 10px;
  min-height: 2.5em;
  max-width: 80%;
`

export default () => {
  const mediaLinks = [
    {
      imgSrc: p1,
      name: "sveriges radio",
      description: "P1 Kropp och själ om injektionsbehandlingar",
      externalUrl:
        "https://sverigesradio.se/sida/avsnitt/934689?programid=1272%C2%A0%C2%A0",
    },
    {
      imgSrc: metroMode,
      name: "Metro mode",
      description:
        "Cassandra Klatzkow om botox mot tandpressande och stresskäke",
      externalUrl:
        "https://cassandraklatzkow.metromode.se/2018/11/01/botox-mot-tandpressande-och-stresskake/",
    },
    {
      imgSrc: breakingNews,
      name: "breaking news",
      description: "Sara diskuterar tandborstteknik med Filip & Fredrik",
      externalUrl:
        "https://www.dplay.se/videos/breaking-news/breaking-news-med-filip-fredrik-21",
    },
    {
      imgSrc: cafe,
      name: "Café",
      description: "Sara tipsar om hur man kan slippa dålig andedräkt",
      externalUrl:
        "https://jonasgrooming.cafe.se/dalig-andedrakt-tandlakaren-tipsar/",
    },
    {
      imgSrc: elle,
      name: "Elle",
      description:
        "Alexandra Kamperhaug om sitt första besök hos Nytorgstandläkarna",
      externalUrl: "https://alexandra.elle.se/a-big-smile/",
    },
    {
      imgSrc: aftonbladet,
      name: "Aftonbladet",
      description:
        "Dålig andedräkt är vanligt – men inte så svårt att bli av med.",
      externalUrl:
        "https://www.aftonbladet.se/halsa/a/RRvJvJ/enkla-knepet-for-att-slippa-dalig-andedrakt",
    },
  ]

  return (
    <>
      <SectionBody rightAlign={true}>
        <h2>Media</h2>

        <p>
          Vi efterfrågas ofta för att ge råd och tips om allt som har att göra
          med tandvård, estetik och att driva en tandläkarklinik. Här kan du
          läsa lite om vad några av våra klienter har att säga om oss, hur du
          kan få bättre andedräkt, eller varför inte titta på när Sara besöker
          Breaking News med Filip och Fredrik?
        </p>
      </SectionBody>
      <MediaTeasers>
        {mediaLinks.map((link, index) => {
          const { externalUrl, name, imgSrc, description } = link
          return (
            <Teaser href={externalUrl} key={`teaser-${index}`} target="_blank">
              <ScrollReveal>
                <ImageWrapper>
                  <img src={imgSrc} />
                </ImageWrapper>

                <TeaserText>
                  <Name>{name}</Name>
                  <Description>{description}</Description>
                </TeaserText>
              </ScrollReveal>
            </Teaser>
          )
        })}
      </MediaTeasers>
    </>
  )
}
