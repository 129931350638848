import React from "react"
import styled, { keyframes } from "styled-components"

import { colors, gridStyles, device } from "../../globalStyles"

const StyledSectionBody = styled.div`
  background-color: ${colors.darkGreen};
  color: ${colors.white};
  grid-column: 1 / -1;
  min-height: 75vh;

  ${gridStyles}
  max-width: initial;

  @media ${device.tabletLandscape} {
    min-height: 100vh;
  }

  > h1 {
    margin: 25vh auto 0;

    @media ${device.tabletLandscape} {
      margin-top: 18vh;
    }

    text-align: center;
    grid-column: 3 / span 8;
    max-width: 1000px;
    z-index: 10;
  }
`

const animOne = keyframes`{
  0 {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(-10%, 0, 0) scale(1.2) rotate(1deg);
  }

  100% {
    transform: translate3d(-5%, 0, 0) scale(1.1);
  }
}`

const animTwo = keyframes`{
  0 {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(-10%, 2%, 0) scale(1.2) rotate(1deg);
  }

  100% {
    transform: translate3d(-5%, 0, 0) scale(1.1);
  }
}`

const animationEasing = `cubic-bezier(.455,.03,.515,.955)`
const GreenTooth = styled.svg`
  position: absolute;
  bottom: 20px;
  width: 100%;

  fill: ${colors.green};
  stroke: ${colors.green};
  animation: ${animOne} 20s ${animationEasing} alternate infinite;

  @media ${device.tabletLandscape} {
    bottom: 0;
    max-height: 40vw;
  }
`

const PinkTooth = styled.svg`
  position: absolute;
  bottom: 20px;
  left: 0;

  transform: scale(1.5);
  animation: ${animOne} 12s ${animationEasing} alternate infinite;
  @media ${device.tabletLandscape} {
    bottom: 0;
    max-height: 30vw;
  }
`

const ToothOutline = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 102%;

  fill: ${colors.lightGreen};
  stroke: ${colors.lightGreen};
  animation: ${animTwo} 20s ${animationEasing} alternate infinite;
`

export default () => {
  return (
    <>
      <StyledSectionBody>
        <h1>Välkommen till Nytorgstandläkarna</h1>
        <GreenTooth viewBox="0 0 1152 568">
          <path
            d="M524.336 1713.87C524.336 1713.87 493.453 1874.92 406.841 1804.97C274.576 1698.42 183.147 1545.64 105.17 1395.76C17.8834 1228.14 -56.396 1054.19 -102.875 870.119C-129.058 765.885 -156.64 654.638 -156.715 546.502C-156.641 428.997 -108.165 307.019 -18.283 232.091C93.9482 138.463 250.015 155.625 382.364 189.113C440.713 204.005 503.073 222.417 559.682 201.791C642.393 172.046 686.519 86.8537 776.953 68.0941C872.381 48.2334 987.411 46.674 1073.84 98.7663C1227.29 191.156 1292.99 391.753 1300.43 563.281C1307.55 729.133 1300.03 895.205 1277.97 1059.49C1262.33 1174.66 1240.96 1288.9 1213.93 1401.78C1190.98 1498.27 1175.65 1617.63 1098.87 1686.84C1064.87 1717.46 1018.06 1758.68 978.84 1709.06C952.15 1675.09 937.525 1626.73 921.113 1587.3C891.969 1517.18 864.356 1446.42 836.056 1375.93C808.837 1307.41 781.273 1238.82 750.572 1171.74C727.965 1122.53 705.422 1047.85 637.732 1073.08C624.243 1078.67 612.227 1087.38 602.629 1098.54C593.031 1109.69 586.112 1122.99 582.417 1137.38C567.411 1188.97 562.67 1392.22 559.845 1457.56C556.474 1526.86 548.18 1595.79 535.028 1663.79L524.336 1713.87Z"
            fill="#60A88B"
          />
        </GreenTooth>
        <PinkTooth viewBox="0 0 1100 464">
          <path
            d="M360.142 1713.87C360.142 1713.87 391.026 1874.92 477.637 1804.97C609.902 1698.42 701.331 1545.64 779.308 1395.76C866.595 1228.14 940.874 1054.19 987.353 870.119C1013.54 765.885 1041.12 654.638 1041.19 546.502C1041.12 428.997 992.643 307.019 902.761 232.091C790.53 138.463 634.463 155.625 502.115 189.113C443.765 204.005 381.405 222.417 324.796 201.791C242.085 172.046 197.959 86.8537 107.525 68.0941C12.097 48.2334 -102.933 46.674 -189.36 98.7663C-342.812 191.156 -408.511 391.753 -415.955 563.281C-423.069 729.133 -415.55 895.205 -393.489 1059.49C-377.853 1174.66 -356.481 1288.9 -329.454 1401.78C-306.505 1498.27 -291.175 1617.63 -214.393 1686.84C-180.389 1717.46 -133.577 1758.68 -94.3617 1709.06C-67.6715 1675.09 -53.0468 1626.73 -36.635 1587.3C-7.49125 1517.18 20.122 1446.42 48.4224 1375.93C75.6408 1307.41 103.205 1238.82 133.906 1171.74C156.513 1122.53 179.056 1047.85 246.746 1073.08C260.235 1078.67 272.251 1087.38 281.849 1098.54C291.447 1109.69 298.366 1122.99 302.061 1137.38C317.067 1188.97 321.808 1392.22 324.633 1457.56C328.004 1526.86 336.298 1595.79 349.45 1663.79L360.142 1713.87Z"
            fill="#F0DDD9"
          />
        </PinkTooth>
      </StyledSectionBody>
      <ToothOutline viewBox="0 0 1152 371">
        <path
          d="M679.969 1805.49C679.969 1805.49 667.166 1968.98 573.32 1909.08C430.037 1817.87 322.205 1676.2 228.062 1535.91C122.697 1379.02 29.5553 1214.4 -37.0821 1036.63C-74.6809 935.949 -114.45 828.454 -126.536 720.996C-139.514 604.21 -104.887 477.602 -23.884 393.154C77.2529 287.639 234.26 287.359 369.509 305.94C429.151 314.258 493.171 325.629 547.139 298.843C626.034 260.095 660.424 170.528 748.215 141.839C840.846 111.502 954.991 97.1748 1046.67 139.345C1209.43 214.118 1297.01 406.176 1323.46 575.816C1348.95 739.851 1359.93 905.731 1356.25 1071.44C1353.5 1187.64 1344.95 1303.55 1330.63 1418.73C1318.54 1517.17 1316.56 1637.5 1247.95 1714.81C1217.55 1749.02 1175.61 1795.18 1131.13 1750.22C1100.83 1719.43 1080.92 1672.99 1060.23 1635.63C1023.48 1569.18 988.177 1501.92 952.222 1435.02C917.561 1369.94 882.549 1304.84 844.587 1241.59C816.653 1195.19 785.954 1123.48 721.486 1156.07C708.701 1163.12 697.727 1173.11 689.428 1185.27C681.128 1197.42 675.729 1211.4 673.655 1226.11C664.472 1279.05 682.337 1481.57 686.788 1546.82C691.135 1616.07 690.549 1685.49 685.032 1754.54L679.969 1805.49Z"
          strokeWidth="40"
          strokeMiterlimit="10"
        />
      </ToothOutline>
    </>
  )
}
