import React from "react"
import styled from "styled-components"

import dentalBg from "../../images/slideshow/13.jpg"
import Tooth from "../../assets/svg/tooth.svg"

import SectionImage, { ImageWrapper } from "./SectionImage"
import {
  SectionBody,
  bodyTextStyles,
  gridStyles,
  device,
} from "../../globalStyles"
import useBreakpoint from "../../hooks/useBreakpoint"
import { useStaticQuery, graphql } from "gatsby"

const StyledSectionImage = styled(SectionImage)`
  top: 60vh;
  grid-row: 3;
`

const Grid = styled.div`
  ${gridStyles};
  ${bodyTextStyles};

  grid-column: 1 / -1;
}
`

const SecondParagraph = styled.p`
  grid-column: 2 / span 4;
  align-self: end;
  margin-bottom: auto;

  > a {
    color: inherit;
    text-decoration: underline;
  }

  @media ${device.tabletLandscape} {
    margin-top: -200px;
  }
`

const PriceList = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 20;

  @media ${device.tablet} {
    height: auto;
  }

  @media ${device.tabletLandscape} {
    grid-column: 6 / span 6;
    top: 80px;
  }

  ul {
    margin: 100px auto 0;
    font-size: 18px;

    position: absolute;
    top: 40%;
    margin: 0;
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    width: 100%;

    @media ${device.tablet} {
      width: 70%;
      top: 30%;
    }

    @media ${device.tabletLandscape} {
      top: 40%;
    }

    @media ${device.desktop} {
      top: 35%;
    }

    b {
      margin-top: 10px;
      display: block;
      text-decoration: underline;
      font-family: "Poppins";
      text-transform: lowercase;
    }
  }
`

const StyledTooth = styled(Tooth)`
  fill: rgba(255, 255, 255, 0.1);

  position: absolute;
  width: 200vw;
  left: -55vw;

  @media ${device.tablet} {
    position: relative;
    width: auto;
    left: initial;
  }

  .tooth__detail {
    fill: none;
  }
`

const PriceItem = styled.li`
  display: flex;
  justify-content: space-between;

  div:last-of-type {
    font-weight: bold;
  }
`

const StyledWrapper = styled(ImageWrapper)`
  margin-top: -5vh;
`

const prices = [
  {
    title: "Tandvårdspriser",
    items: [
      {
        title: "Undersökning",
        price: "1095kr",
      },
      {
        title: "Tandhygienist",
        price: "1450-2100kr",
      },
      {
        title: "Tandblekning",
        price: "4000kr",
      },
      {
        title: "Lagning",
        price: "1200-3010kr",
      },
      {
        title: "Tandsmycken",
        price: "1250-1500kr",
      },
      {
        title: "Invisalign (tandställning)",
        price: "40-50 000kr för båda käkar",
      },
    ],
  },
  {
    title: "Permanenta grillz",
    items: [
      {
        title: "Guld 18 karat",
        price: "10 000kr",
      },
      {
        title: `Kobolt-krom "silver"`,
        price: "7000kr",
      },
    ],
  },
  {
    title: "Avtagbara grillz",
    items: [
      {
        title: "Guld 18 karat",
        price: "4500kr",
      },
      {
        title: `Kobolt-krom "silver"`,
        price: "3000kr",
      },
    ],
  },
]

export default () => {
  const { sanitySiteSettings } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        priceList {
          asset {
            url
          }
        }
      }
    }
  `)

  const { priceList } = sanitySiteSettings
  const { isDesktop } = useBreakpoint()
  return (
    <>
      <SectionBody rightAlign={true}>
        <h2>tandvård</h2>
        <p>
          Vi ser mer än dina tänder, vi ser dig och ditt personliga behov och
          har stor vana av tandvårdsrädda patienter. Vi jobbar tillsammans, som
          ett team, för att hitta lösningar för just dig.
        </p>
        <p>
          Vi erbjuder visdomstandkirugi, vi har intra-oral scanner så du slipper
          kladdig avtryckstagning.
        </p>
      </SectionBody>

      <Grid>
        <PriceList>
          <StyledTooth />

          <ul>
            {prices.map((priceGroup, index) => {
              const { title, items } = priceGroup

              return (
                <React.Fragment key={`price-group-${index}`}>
                  <b>{title}</b>
                  {items.map((priceItem, index) => {
                    const { title, price } = priceItem

                    return (
                      <PriceItem key={`price-${index}`}>
                        <div>{title}</div>
                        <div>{price}</div>
                      </PriceItem>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </ul>
        </PriceList>

        <SecondParagraph>
          Priser på behandlingar kan variera beroende på omfattning och
          individuellt fall. Vi rekommenderar att komma på en undersökning där
          vi kan ge en utförlig kostnadsberäkning för just dig.
          <br />
          <br />
          <br />
          Eller läs vår fullständiga prislista{" "}
          <a href={priceList?.asset?.url} target="_blank">
            här.
          </a>
        </SecondParagraph>
      </Grid>

      {isDesktop && (
        <StyledWrapper>
          <StyledSectionImage imgSrc={dentalBg} />
        </StyledWrapper>
      )}
    </>
  )
}
